import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { feature, social, thumbnail } = useStaticQuery(graphql`
    query {
      feature: file(relativePath: { eq: "api-not-enough.jpeg" }) {
        ...InsightHeaderImage
      }
      social: file(relativePath: { eq: "api-not-enough.jpeg" }) {
        ...SocialImage
      }
      thumbnail: file(relativePath: { eq: "api-not-enough.jpeg" }) {
          ...ThumbnailImage
        }
    }
  `);

  return {
    featured: {
      imgData: feature.childImageSharp.fluid,
      alt: "Article Hero"
    },
    social: {
      imgData: social.childImageSharp.fixed,
      alt: "Article Social Share"
    },
    thumbnail: {
      imgData: thumbnail.childImageSharp.fluid,
      alt: "Article Thumbnail"
    },
  };
};
