import React from "react";
import SEO from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import FlexibleLayout from "../../components/FlexibleLayout";

export default ({ data, pageContext }) => {
  const {
    page: {
      uri,
      slug,
      theme,
      seo,
      flexchildren
    }
  } = data;

  return (
    <>
      <SEO seoData={seo} uri={uri} />
      <Layout bodyClass={slug} theme={theme}>
        {flexchildren && flexchildren.map((layout, index) => {
          return <FlexibleLayout key={index} layout={layout} />;
        })}
      </Layout>
    </>
  );
};
