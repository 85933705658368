import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { img } = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "diem-stacked-1.jpg" }) {
        ...FluidImage
      }
    }
  `);

  return {
    imgData: img.childImageSharp.fluid,
    alt: "Article Hero"
  };
};
