import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { right, social, thumbnail } = useStaticQuery(graphql`
    query {
      right: file(relativePath: { eq: "fiserv-hero.png" }) {
        ...FluidImage
      }
      social: file(relativePath: { eq: "api-not-enough.jpeg" }) {
        ...SocialImage
      }
      thumbnail: file(relativePath: { eq: "fiserv-thumbnail.jpeg" }) {
        ...ThumbnailImage
      }

    }
  `);

  return {
    right: {
      imgData: right.childImageSharp.fluid,
      alt: "Article Hero"
    },
    social: {
      imgData: social.childImageSharp.fixed,
      alt: "Article Social Share"
    },
    thumbnail: {
      imgData: thumbnail.childImageSharp.fluid,
      alt: "Article Thumbnail"
    },
  };
};
