import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { right, social, thumbnail, stacked1, stacked2 } = useStaticQuery(graphql`
    query {
      right: file(relativePath: { eq: "diem-hero.png" }) {
        ...FluidImage
      }
      social: file(relativePath: { eq: "api-not-enough.jpeg" }) {
        ...SocialImage
      }
      thumbnail: file(relativePath: { eq: "diem-thumbnail.png" }) {
        ...ThumbnailImage
      },
      stacked1: file(relativePath: { eq: "diem-stacked-1.png" }) {
        ...FluidImage
      }
      stacked2: file(relativePath: { eq: "diem-stacked-2.png" }) {
        ...FluidImage
      }
    }
  `);

  return {
    right: {
      imgData: right.childImageSharp.fluid,
      alt: "Article Hero"
    },
    social: {
      imgData: social.childImageSharp.fixed,
      alt: "Article Social Share"
    },
    thumbnail: {
      imgData: thumbnail.childImageSharp.fluid,
      alt: "Article Thumbnail"
    },
  };
};
