import { useDiem, useFiserv, useNetapp, useTwitch, useTwitter } from "../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "dark",
        type: "dark",
        header: "dark"
      },
      title: "Work",
      uri: "/work/",
      slug: "work",
      seo: {
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Work - Vivid",
        opengraphType: "article",
        title: "Work - Vivid",
      },
      flexchildren: [
        {
          __typename: "PageHeader",
          description:
            "Learn how we help our clients meet and<br />\r\nexceed their business goals.",
          heading: "Delivering success<br />\r\nat every stage",
        },
        {
          __typename: "GridCard",
          items: [
            {
              __typename: "WpWork",
              title: "Diem Association",
              uri: "/work/building-the-future-of-payments/",
              baseFields: {
                previewDescription: "Building the future of payments",
                previewImage: {
                  altText: "",
                  useImage: useDiem,
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAE7klEQVQ4y23Ua0xTdxgG8PPVbV9mtmnmLjHEzSxxGkUmc4tzoigwlHEpF0Eu1pYCLaUUChRqoVC5FRCqWHVTM81izBZdYnRGKRxKC7SAcilXFZNli1kMxF2yePa8e1s16uKHf86Hk/zy/M953lf4pvUuTtju4HjTbRxrmMPRQ3M4Yp1FR+0M2mum0WaeQsvBSdiq/GgyTqCxYhz1ZWOwlo6iTj8KS/Et1BTdlMyFI3RQMywKJ1vu4njzHTgab6OzPgDOwl7HmGUah6un0GqeRItpEs2VfsYm0FA+jkOGMdSVjKJWz5juJqq1IxJjZFIz+AJmfYa11wSec8F0TZXjj5OVj70MA2OSST1ElflDovCyZO2WGTQaR/kqLjTz9VoqZ9FsnGZ0ErUlt2DRjfC7kacYOJnEGBlVPlF4HutgLHDF5oppWC3XUGX/ESXVF6FRfw9D/lUYVd1oKuOrl00zOs7YCGNDYEyqUPmoPNcrCs9jgR9wxDqDc44pdDkHMHN/kfrmfqeLvnlqveCiyrZLiN3dhKQYO0oUVxjzBjAwJpUrvWRQDIrCiz9gCjbTKC5dGMK0f54eLj4EEeGvv//Bwp//Yn7hETm+89C+9NO0Zp2KsmVnYFaPoUw5IDFGpfIBUeh4ivHHD4A1xV58uaEJ0Z+3kTztKBotF9B9bQpzM/ew+OAP/PbLIhYXHiEhroP2RLbDrBlF6YF+iTHS5/SLwtNq2Ex+PpOo1g0iavNh7AjroJXL4vHuGzG0da2dwjdkU7xsP9UcPIF7dxcgS3BQ7A47qvKGUbK/X2KMirM9ovC4Z1zayolg10yF/dge2oyI9W207oMMfLwqhaI2HaPQ1Tp6bcka+nR9Ps1OPUBsnINiIjpgzPWhOMcj6bI8VJTpFoXHpeUJMI4HS2spHoIy9Rx2htnpnddjsWLpLpJFnqTUaAfFbbNT8q5Ompr4FTvj2mnnthZUKHwoynJLjJE2o08UgliFHw1chTo946ZBePvHMOKdp7Onr8CgdZBCdp6iP2ulHRubafcXbfD77yOCwegIGwxyL7T7+qTCjD7SpDMYwOrLh9FguozW+jOw1dtQVKiGLDkR2dmZOHXqW/x81Yle5yTOHvfBaryK69c9CNuupcgth1CSPQRNukvS7HVRQZpLFBrK/bDo3TDqjsFc0Yhacw3qa820PyuNVq8OoVdfWULLl7+FTeEboS1SUXuHjcI/CcfG9VuQmWCHXt7DYI9UkOqi/JReUQjMprV0jEdpggvqgZ4La8g/D53qMDJT1YiKjEJo6FpauXIFvb3sfYS8F4YPQzbjzaUh+ComFgq5FpnxDqkgxU0qWY8oWJ8MenWRDybNIIz5AzAoPSiRu6HL6YIm8yfIUzuRldgKdcZlyJPOI2xtEj5atRV7tpuR/fUPUCbekBij3CQG/7c1UFXgRUXeALffzYXt40q4oMvqgzbTBXW6k083tBluqPf2Ii9FRK6sC8qkLkmZ2E2KhO5nIC/IwNZgMDDsPl4EDOcOwqDo57QBuJdhFwr39SI/zQlV8g0+1/k4kZfc8yyhpfhmcBWZC4eDCYNYno+/p5dTMnhggMF+6HM8gQKD+8YJ+6DhhAWpjHNKxiTGiFOKQvUTzKQZCqYLYLyGghjPZ2CsgpAuyx08hQEsna+fxldODmJQJnRLigQnHYh3iv8BtcUbmiIjp8QAAAAASUVORK5CYII=",
                        aspectRatio: 1.0050251256281406,
                        src: "/static/d7381bbe964ec1ddfe999bbcf9014457/a273f/Vivid_success_story_diem_ui_design_thumbnail_1.png",
                        srcSet:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/5224a/Vivid_success_story_diem_ui_design_thumbnail_1.png 200w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/d786d/Vivid_success_story_diem_ui_design_thumbnail_1.png 400w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/a273f/Vivid_success_story_diem_ui_design_thumbnail_1.png 708w",
                        srcWebp:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/bf5a7/Vivid_success_story_diem_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/9e195/Vivid_success_story_diem_ui_design_thumbnail_1.webp 200w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/40a1d/Vivid_success_story_diem_ui_design_thumbnail_1.webp 400w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/bf5a7/Vivid_success_story_diem_ui_design_thumbnail_1.webp 708w",
                        sizes: "(max-width: 708px) 100vw, 708px",
                        presentationWidth: 708,
                      },
                    },
                  },
                },
              },
              featuredImage: {
                node: {
                  altText: "",
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAE7klEQVQ4y23Ua0xTdxgG8PPVbV9mtmnmLjHEzSxxGkUmc4tzoigwlHEpF0Eu1pYCLaUUChRqoVC5FRCqWHVTM81izBZdYnRGKRxKC7SAcilXFZNli1kMxF2yePa8e1s16uKHf86Hk/zy/M953lf4pvUuTtju4HjTbRxrmMPRQ3M4Yp1FR+0M2mum0WaeQsvBSdiq/GgyTqCxYhz1ZWOwlo6iTj8KS/Et1BTdlMyFI3RQMywKJ1vu4njzHTgab6OzPgDOwl7HmGUah6un0GqeRItpEs2VfsYm0FA+jkOGMdSVjKJWz5juJqq1IxJjZFIz+AJmfYa11wSec8F0TZXjj5OVj70MA2OSST1ElflDovCyZO2WGTQaR/kqLjTz9VoqZ9FsnGZ0ErUlt2DRjfC7kacYOJnEGBlVPlF4HutgLHDF5oppWC3XUGX/ESXVF6FRfw9D/lUYVd1oKuOrl00zOs7YCGNDYEyqUPmoPNcrCs9jgR9wxDqDc44pdDkHMHN/kfrmfqeLvnlqveCiyrZLiN3dhKQYO0oUVxjzBjAwJpUrvWRQDIrCiz9gCjbTKC5dGMK0f54eLj4EEeGvv//Bwp//Yn7hETm+89C+9NO0Zp2KsmVnYFaPoUw5IDFGpfIBUeh4ivHHD4A1xV58uaEJ0Z+3kTztKBotF9B9bQpzM/ew+OAP/PbLIhYXHiEhroP2RLbDrBlF6YF+iTHS5/SLwtNq2Ex+PpOo1g0iavNh7AjroJXL4vHuGzG0da2dwjdkU7xsP9UcPIF7dxcgS3BQ7A47qvKGUbK/X2KMirM9ovC4Z1zayolg10yF/dge2oyI9W207oMMfLwqhaI2HaPQ1Tp6bcka+nR9Ps1OPUBsnINiIjpgzPWhOMcj6bI8VJTpFoXHpeUJMI4HS2spHoIy9Rx2htnpnddjsWLpLpJFnqTUaAfFbbNT8q5Ompr4FTvj2mnnthZUKHwoynJLjJE2o08UgliFHw1chTo946ZBePvHMOKdp7Onr8CgdZBCdp6iP2ulHRubafcXbfD77yOCwegIGwxyL7T7+qTCjD7SpDMYwOrLh9FguozW+jOw1dtQVKiGLDkR2dmZOHXqW/x81Yle5yTOHvfBaryK69c9CNuupcgth1CSPQRNukvS7HVRQZpLFBrK/bDo3TDqjsFc0Yhacw3qa820PyuNVq8OoVdfWULLl7+FTeEboS1SUXuHjcI/CcfG9VuQmWCHXt7DYI9UkOqi/JReUQjMprV0jEdpggvqgZ4La8g/D53qMDJT1YiKjEJo6FpauXIFvb3sfYS8F4YPQzbjzaUh+ComFgq5FpnxDqkgxU0qWY8oWJ8MenWRDybNIIz5AzAoPSiRu6HL6YIm8yfIUzuRldgKdcZlyJPOI2xtEj5atRV7tpuR/fUPUCbekBij3CQG/7c1UFXgRUXeALffzYXt40q4oMvqgzbTBXW6k083tBluqPf2Ii9FRK6sC8qkLkmZ2E2KhO5nIC/IwNZgMDDsPl4EDOcOwqDo57QBuJdhFwr39SI/zQlV8g0+1/k4kZfc8yyhpfhmcBWZC4eDCYNYno+/p5dTMnhggMF+6HM8gQKD+8YJ+6DhhAWpjHNKxiTGiFOKQvUTzKQZCqYLYLyGghjPZ2CsgpAuyx08hQEsna+fxldODmJQJnRLigQnHYh3iv8BtcUbmiIjp8QAAAAASUVORK5CYII=",
                        aspectRatio: 1.0050251256281406,
                        src: "/static/d7381bbe964ec1ddfe999bbcf9014457/a273f/Vivid_success_story_diem_ui_design_thumbnail_1.png",
                        srcSet:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/5224a/Vivid_success_story_diem_ui_design_thumbnail_1.png 200w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/d786d/Vivid_success_story_diem_ui_design_thumbnail_1.png 400w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/a273f/Vivid_success_story_diem_ui_design_thumbnail_1.png 708w",
                        srcWebp:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/bf5a7/Vivid_success_story_diem_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/d7381bbe964ec1ddfe999bbcf9014457/9e195/Vivid_success_story_diem_ui_design_thumbnail_1.webp 200w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/40a1d/Vivid_success_story_diem_ui_design_thumbnail_1.webp 400w,\n/static/d7381bbe964ec1ddfe999bbcf9014457/bf5a7/Vivid_success_story_diem_ui_design_thumbnail_1.webp 708w",
                        sizes: "(max-width: 708px) 100vw, 708px",
                        presentationWidth: 708,
                      },
                    },
                  },
                },
              },
            },
            {
              __typename: "WpWork",
              title: "Fiserv",
              uri: "/work/fiserv-dashboard/",
              baseFields: {
                previewDescription: "Realizing the untapped value of data",
                previewImage: {
                  altText: "",
                  useImage: useFiserv,
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIEAP/aAAwDAQACEAMQAAAB1z35pautSdzZtQJ//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxASIDH/2gAIAQEAAQUC8iWbYKqyCh0gHH//xAAZEQEAAgMAAAAAAAAAAAAAAAABAAIDEBH/2gAIAQMBAT8BvkRnY6//xAAXEQEBAQEAAAAAAAAAAAAAAAABAhEg/9oACAECAQE/AZgTXj//xAAcEAACAQUBAAAAAAAAAAAAAAABEQACECAhMXH/2gAIAQEABj8CnLGklOaXrx//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUEQYXH/2gAIAQEAAT8hoK6IHQ9wsqZMHp1CUoflMAEIeP/aAAwDAQACAAMAAAAQEOg+/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEWH/2gAIAQMBAT8QqKRqX//EABkRAQACAwAAAAAAAAAAAAAAAAEAEBEh4f/aAAgBAgEBPxBunIpnUa//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhMVFhcYH/2gAIAQEAAT8QVLgVfBrFISjkL1pBVHSJqQseOOT7iHXWbRej39P3AZ0EMMQ6wcb/2Q==",
                        aspectRatio: 0.9950248756218906,
                        src: "/static/b9ae1bfe978a8ea68bd7fb531263718a/8f733/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/4fa6c/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 200w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/64b17/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 400w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/8f733/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 702w",
                        srcWebp:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/bb2d1/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/9e195/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 200w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/40a1d/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 400w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/bb2d1/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 702w",
                        sizes: "(max-width: 702px) 100vw, 702px",
                        presentationWidth: 702,
                      },
                    },
                  },
                },
              },
              featuredImage: {
                node: {
                  altText: "",
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIEAP/aAAwDAQACEAMQAAAB1z35pautSdzZtQJ//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxASIDH/2gAIAQEAAQUC8iWbYKqyCh0gHH//xAAZEQEAAgMAAAAAAAAAAAAAAAABAAIDEBH/2gAIAQMBAT8BvkRnY6//xAAXEQEBAQEAAAAAAAAAAAAAAAABAhEg/9oACAECAQE/AZgTXj//xAAcEAACAQUBAAAAAAAAAAAAAAABEQACECAhMXH/2gAIAQEABj8CnLGklOaXrx//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUEQYXH/2gAIAQEAAT8hoK6IHQ9wsqZMHp1CUoflMAEIeP/aAAwDAQACAAMAAAAQEOg+/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEWH/2gAIAQMBAT8QqKRqX//EABkRAQACAwAAAAAAAAAAAAAAAAEAEBEh4f/aAAgBAgEBPxBunIpnUa//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhMVFhcYH/2gAIAQEAAT8QVLgVfBrFISjkL1pBVHSJqQseOOT7iHXWbRej39P3AZ0EMMQ6wcb/2Q==",
                        aspectRatio: 0.9950248756218906,
                        src: "/static/b9ae1bfe978a8ea68bd7fb531263718a/8f733/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/4fa6c/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 200w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/64b17/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 400w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/8f733/Vivid_success_story_fiserv_ui_design_thumbnail_1.jpg 702w",
                        srcWebp:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/bb2d1/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/b9ae1bfe978a8ea68bd7fb531263718a/9e195/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 200w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/40a1d/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 400w,\n/static/b9ae1bfe978a8ea68bd7fb531263718a/bb2d1/Vivid_success_story_fiserv_ui_design_thumbnail_1.webp 702w",
                        sizes: "(max-width: 702px) 100vw, 702px",
                        presentationWidth: 702,
                      },
                    },
                  },
                },
              },
            },
            {
              __typename: "WpWork",
              title: "Twitter",
              uri: "/work/twitter-data/",
              baseFields: {
                previewDescription: "Monetizing insights through data alchemy",
                previewImage: {
                  altText: "",
                  useImage: useTwitter,
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAACBAP/2gAMAwEAAhADEAAAAZvzCiXpQ1GMjPSCv//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMQERIx/9oACAEBAAEFAlmmkBO3Mg5f/8QAFhEBAQEAAAAAAAAAAAAAAAAAAhEg/9oACAEDAQE/ASDMf//EABgRAQADAQAAAAAAAAAAAAAAAAIBICEx/9oACAECAQE/AU1C5lP/xAAbEAACAQUAAAAAAAAAAAAAAAAAASEQESBhcf/aAAgBAQAGPwKux2ldJw//xAAcEAADAAMAAwAAAAAAAAAAAAAAAREhMUEQYaH/2gAIAQEAAT8hruE2ZeK202L6N4XvHwpL1BladQnUf//aAAwDAQACAAMAAAAQrO/A/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQMBAT8QZtexMchX/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAgEBPxCEBOq7/8QAHRABAQACAQUAAAAAAAAAAAAAAREAIUEQMVFxsf/aAAgBAQABPxCxGDcDAKREY07PjobTugF+seoxhCEku2sJPCu7v3iwtETGFeTP/9k=",
                        aspectRatio: 0.9900990099009901,
                        src: "/static/13f5496e7f590e95029c9046b28345b7/e378d/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/13f5496e7f590e95029c9046b28345b7/4fa6c/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 200w,\n/static/13f5496e7f590e95029c9046b28345b7/64b17/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 400w,\n/static/13f5496e7f590e95029c9046b28345b7/e378d/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 699w",
                        srcWebp:
                          "/static/13f5496e7f590e95029c9046b28345b7/7bf24/Vivid_success_story_twitter_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/13f5496e7f590e95029c9046b28345b7/9e195/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 200w,\n/static/13f5496e7f590e95029c9046b28345b7/40a1d/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 400w,\n/static/13f5496e7f590e95029c9046b28345b7/7bf24/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 699w",
                        sizes: "(max-width: 699px) 100vw, 699px",
                        presentationWidth: 699,
                      },
                    },
                  },
                },
              },
              featuredImage: {
                node: {
                  altText: "",
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAACBAP/2gAMAwEAAhADEAAAAZvzCiXpQ1GMjPSCv//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMQERIx/9oACAEBAAEFAlmmkBO3Mg5f/8QAFhEBAQEAAAAAAAAAAAAAAAAAAhEg/9oACAEDAQE/ASDMf//EABgRAQADAQAAAAAAAAAAAAAAAAIBICEx/9oACAECAQE/AU1C5lP/xAAbEAACAQUAAAAAAAAAAAAAAAAAASEQESBhcf/aAAgBAQAGPwKux2ldJw//xAAcEAADAAMAAwAAAAAAAAAAAAAAAREhMUEQYaH/2gAIAQEAAT8hruE2ZeK202L6N4XvHwpL1BladQnUf//aAAwDAQACAAMAAAAQrO/A/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQMBAT8QZtexMchX/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAgEBPxCEBOq7/8QAHRABAQACAQUAAAAAAAAAAAAAAREAIUEQMVFxsf/aAAgBAQABPxCxGDcDAKREY07PjobTugF+seoxhCEku2sJPCu7v3iwtETGFeTP/9k=",
                        aspectRatio: 0.9900990099009901,
                        src: "/static/13f5496e7f590e95029c9046b28345b7/e378d/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/13f5496e7f590e95029c9046b28345b7/4fa6c/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 200w,\n/static/13f5496e7f590e95029c9046b28345b7/64b17/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 400w,\n/static/13f5496e7f590e95029c9046b28345b7/e378d/Vivid_success_story_twitter_ui_design_thumbnail_1.jpg 699w",
                        srcWebp:
                          "/static/13f5496e7f590e95029c9046b28345b7/7bf24/Vivid_success_story_twitter_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/13f5496e7f590e95029c9046b28345b7/9e195/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 200w,\n/static/13f5496e7f590e95029c9046b28345b7/40a1d/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 400w,\n/static/13f5496e7f590e95029c9046b28345b7/7bf24/Vivid_success_story_twitter_ui_design_thumbnail_1.webp 699w",
                        sizes: "(max-width: 699px) 100vw, 699px",
                        presentationWidth: 699,
                      },
                    },
                  },
                },
              },
            },
            {
              __typename: "WpWork",
              title: "Twitch",
              uri: "/work/twitch/",
              baseFields: {
                previewDescription: "Reimagining the live-streaming experience",
                previewImage: {
                  altText: "",
                  useImage: useTwitch,
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQP/2gAMAwEAAhADEAAAAct9GzW8lBVkAgI//8QAGxAAAgIDAQAAAAAAAAAAAAAAAgMAAQQQERP/2gAIAQEAAQUCAbYWQnyGJZS2PyANc7Lvuv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABcRAQADAAAAAAAAAAAAAAAAAAECESD/2gAIAQIBAT8Bkt4//8QAGxAAAQUBAQAAAAAAAAAAAAAAAQACEBIhEVH/2gAIAQEABj8Cq1NI32LFcnY//8QAGhABAAIDAQAAAAAAAAAAAAAAAQARECExYf/aAAgBAQABPyEb0Yaht1LgslAdE7Js0VLlhsjK0r7j/9oADAMBAAIAAwAAABDgADz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EEEhR5//xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMUFhEP/aAAgBAQABPxAQB3VYB65FWGnr8PDHbCRsPRprECKSTJ9r0x2yEkTjLj9q6qv5/9k=",
                        aspectRatio: 1,
                        src: "/static/8979d948a14f5c33e39a29d3e94d2977/a1eb1/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/4fa6c/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/64b17/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 400w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a1eb1/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 800w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a6c62/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 1200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/61ce6/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 1338w",
                        srcWebp:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/b0751/Vivid_success_story_twitch_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/9e195/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/40a1d/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 400w,\n/static/8979d948a14f5c33e39a29d3e94d2977/b0751/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 800w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a7c53/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 1200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/02d80/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 1338w",
                        sizes: "(max-width: 800px) 100vw, 800px",
                        presentationWidth: 800,
                      },
                    },
                  },
                },
              },
              featuredImage: {
                node: {
                  altText: "",
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQP/2gAMAwEAAhADEAAAAct9GzW8lBVkAgI//8QAGxAAAgIDAQAAAAAAAAAAAAAAAgMAAQQQERP/2gAIAQEAAQUCAbYWQnyGJZS2PyANc7Lvuv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABcRAQADAAAAAAAAAAAAAAAAAAECESD/2gAIAQIBAT8Bkt4//8QAGxAAAQUBAQAAAAAAAAAAAAAAAQACEBIhEVH/2gAIAQEABj8Cq1NI32LFcnY//8QAGhABAAIDAQAAAAAAAAAAAAAAAQARECExYf/aAAgBAQABPyEb0Yaht1LgslAdE7Js0VLlhsjK0r7j/9oADAMBAAIAAwAAABDgADz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EEEhR5//xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMUFhEP/aAAgBAQABPxAQB3VYB65FWGnr8PDHbCRsPRprECKSTJ9r0x2yEkTjLj9q6qv5/9k=",
                        aspectRatio: 1,
                        src: "/static/8979d948a14f5c33e39a29d3e94d2977/a1eb1/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg",
                        srcSet:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/4fa6c/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/64b17/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 400w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a1eb1/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 800w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a6c62/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 1200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/61ce6/Vivid_success_story_twitch_ui_design_thumbnail_1.jpg 1338w",
                        srcWebp:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/b0751/Vivid_success_story_twitch_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/8979d948a14f5c33e39a29d3e94d2977/9e195/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/40a1d/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 400w,\n/static/8979d948a14f5c33e39a29d3e94d2977/b0751/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 800w,\n/static/8979d948a14f5c33e39a29d3e94d2977/a7c53/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 1200w,\n/static/8979d948a14f5c33e39a29d3e94d2977/02d80/Vivid_success_story_twitch_ui_design_thumbnail_1.webp 1338w",
                        sizes: "(max-width: 800px) 100vw, 800px",
                        presentationWidth: 800,
                      },
                    },
                  },
                },
              },
            },
            {
              __typename: "WpWork",
              title: "NetApp",
              uri: "/work/netapp/",
              baseFields: {
                previewDescription: "Unlocking the potential of partners",
                previewImage: {
                  altText: "",
                  useImage: useNetapp,
                  localFile: {
                    childImageSharp: {
                      fluid: {
                        base64:
                          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAE4UlEQVQ4y3WU+0+TVxzGDyqiU4YEQYc3hNJyabnI1SKKF0JkwJw6LiriUMRtFIqlkXKxKC2XtlAslJZLoRQoL71QWmAqUaOJWUxmsiX7Yf/Nc3bebjj8wR9OTvIm55Pn/X6f5yEnquSIq2lF/K02JNQ9QmK9CqL7XUj6SY2UpicQt2ggediHdOUgMtsNONU5jOzHI8h78gynNaMoGBxHocGKc8YJXBidAuFhJ28qEH9bCcGP7RDe60RSYzeSf+5BquwpJK1apLUNIOORDqc6hpDVZUSOegT5mjEUDIzjjM6Cc8MTOP9sEhfN0yC8si/C5Aym6EcGU8fDcnpGcbrfigLDFPK0z5CvHWHKJj/Biq0zIPxvfklZxiM9srpHkNUzhswuA1Ja1RDWyyG+cR8X78hR0aJiQAuD2YKwkik7CD8zHibaDlMMIvWhFoLGdjZbGVIrG3C2tgV3lVpojTPUvrhOnUsB2tCsxBm9CSWTcww2i9JZB0hwAZ/BBpBer8DZG014oBqEZthGbQ4/nbItUXXvEK2ubaBCcQ5I+BHsiU1EqcmCEpsDpTNzKHMsgPDb3D6zlLZBlDd3YmTITFVdfbTiSjU9ejIZJPRrEBLCzi6QnZEgUfH0q1ghLenXoXTeibK5eVQsLoLw1vi0TWaPzO5RpF6vQwgJpYTsYIB92Bd1DMcSxDQpLY+GR5+kJCoO5EgKdkWeoBc6ulHOuVC+sICry0sgQZ9tWYNtM6vHjJSaBuzeHU4ThOkQiDIQJ0qj0cdFNCIulZKjyZQcF4NIChESI6SFMjm+9/lwdWkRN70c+85UbfksU2VA9lMzMhsVCN0TQSO/iUdY9HGQA7GU8MpOpFByKJ6S/YfYDNkhO2l6VTVq1gOocTlxz78Mwidgu2lzNWbkKtQIi4ihJJydiMMgeyPZHU3JPnaH7Mae/QdoRFQ0Dh+MoeUPHqA24MVdH4emdRdIME48rJvBephZ+8woUOsReuAgyI4wumtvONhjGnM4FgkJiRAlJSNRJIJIKKTKNiXt2PCjzrcE2YYLyhceED6bWzA+m1IWp6KhCUiycyEQCKhQlARBYiJSxWJIJBIUX7pE62/XUXVXF1X5PLjtd0HGlLW9cKP7lReEz+UWLE87hnyWzTK5EhnpaVQqldKayiqqlMupyWCgHpeLPt/cxNqbNxh6+xp1AQbbcEP50oPHr73oe7cCkvfUFITl95tx0TgF45IbZoOeWi0W6vauYHHVD2tgDT3cMu7bbLg2YcGVaQt+sE+ggZtFs8+B9g0nnmxy0L1hM5QyVdI+1hz9o1COT2Jg3AKZfhjXOx+jsLUNWfKHkDS3sAQpkNuhwnl1J77VqlFp7McdyxB+cYyh3TON3g0HjK+cIMEKYp12Tm/GeZ0JORo9sjU6SHXDKDKaUDxqxmWrFRXTk6i0T6N2wYZG9xxaA050POfQ+8oFwzsvxn/zYfZ3P0iwHFmfFZmmcGGcb4w5FvJ5FqUF5n4nvuM4XHMv45bPhXsBN2S/eqDc9LKZrbCZ+WB8vwrrBz/sHwPg/lwDKTJOfNZnfGtcDgZ9HuUsm1c5J256ONxdXQ767N9tetD3dgXD732wfFgNKuP+CMD/1zoIX9v/w+zBCuJbgw86n00+TnwCmv7zGW+N7TD7Rx62FoS9/HsD/wB0PHw5bHq+vQAAAABJRU5ErkJggg==",
                        aspectRatio: 1,
                        src: "/static/0d49fd2b7f5a19af9000f892f0efdd87/f9ff4/Vivid_success_story_netapp_ui_design_thumbnail_1.png",
                        srcSet:
                          "/static/0d49fd2b7f5a19af9000f892f0efdd87/5224a/Vivid_success_story_netapp_ui_design_thumbnail_1.png 200w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/d786d/Vivid_success_story_netapp_ui_design_thumbnail_1.png 400w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/f9ff4/Vivid_success_story_netapp_ui_design_thumbnail_1.png 800w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/6cf9f/Vivid_success_story_netapp_ui_design_thumbnail_1.png 924w",
                        srcWebp:
                          "/static/0d49fd2b7f5a19af9000f892f0efdd87/b0751/Vivid_success_story_netapp_ui_design_thumbnail_1.webp",
                        srcSetWebp:
                          "/static/0d49fd2b7f5a19af9000f892f0efdd87/9e195/Vivid_success_story_netapp_ui_design_thumbnail_1.webp 200w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/40a1d/Vivid_success_story_netapp_ui_design_thumbnail_1.webp 400w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/b0751/Vivid_success_story_netapp_ui_design_thumbnail_1.webp 800w,\n/static/0d49fd2b7f5a19af9000f892f0efdd87/5a3f3/Vivid_success_story_netapp_ui_design_thumbnail_1.webp 924w",
                        sizes: "(max-width: 800px) 100vw, 800px",
                        presentationWidth: 800,
                      },
                    },
                  },
                },
              },
              featuredImage: null,
            },
          ],
          callToAction: {
            buttonLink: {
              target: "",
              title: "Contact",
              url: "https://wp.Vivid.net/contact/",
            },
            description:
              "Get in touch with us for more info on our past work. We’re always interested in making new connections - whether you have a project in mind or just want to chat, we’d love to hear from you.",
            title: "Looking for more? ",
          },
        },
      ],
    },
  },
};
